html, body, #root, .app {
    height: 100vh;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    font-family: Poppins-Bold;
    overflow: auto
}

a {
    text-decoration: none;
    color: inherit;
}

a:hover {
    color: #1976d2;
    text-decoration: underline;
}

* {
    font-family: Poppins-Bold;
}

.active-menu {
    font-weight: 700;
    color: #1976d2;
}

.ellipsisText {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.css-14wf2qq {
    display: none;
}
.css-uob957-MuiListSubheader-root {
    z-index: 0 !important;
}